
export default {
  components: {
    TitlePage: () => import("@/components/TitlePage"),
    ImgCard: () => import("@/components/ImgCard"),
  },
  data() {
    return {
      title: null,
      imgHeader: null,
      img: null,
      lang: this.$router.currentRoute.params.lang,
      root: "accommodation",
    };
  },
  metaInfo () {
    return {
      title: 'Accomodation',
      titleTemplate: '%s | Kaewsamui resort',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: 'Accomodation' },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  mounted() {
    this.getAccommodation();
  },
  methods: {
    async getAccommodation() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "accommodation"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      console.log(results);
      this.imgHeader = results[0].data.img;
      this.title = results[0].data.header;
      this.img = results[0].data.body;
    },
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getAccommodation();
    },
  },
};
